
import { defineComponent, ref, inject, Ref, onMounted } from "vue";
import { useStore } from "../store";
import { AxiosStatic } from "axios";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { usePermissions } from "/src/composable/usePermissions";
import { Consents } from "/types";
import { actions } from "/src/utils/const";

export interface Model {
  termsAgreement: boolean;
  marketingAgreement: boolean;
  commercialAgreement: boolean;
  consentsVersion: number | null;
}

export const AgreementsComponent = defineComponent({
  setup() {
    const checked: Ref<boolean> = ref(false);
    const processingToTheNextPage: Ref<boolean> = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const iactions = actions;
    const { hasPermission } = usePermissions();
    const consentsVersion: Ref<number | null> = ref(null);
    const model: Ref<Model> = ref({
      termsAgreement: false,
      marketingAgreement: false,
      commercialAgreement: false,
      consentsVersion: 0,
    });
    const termsAgreement: Ref<string> = ref("");
    const marketingAgreement: Ref<string> = ref("");
    const tradeAgreement: Ref<string> = ref("");

    const acceptAgreements = async () => {
      processingToTheNextPage.value = true;
      model.value.consentsVersion = consentsVersion.value;
      await http.put(`patient-portal/api/agreements`, model.value);
      store.commit("setConsentsVersion", model.value.consentsVersion);
      store.commit("setUserTermsAgreement", model.value.termsAgreement);
      store.commit("setUserMarketingAgreement", model.value.marketingAgreement);
      store.commit(
        "setUserCommercialAgreement",
        model.value.commercialAgreement
      );
      store.commit("setConsentsVersionChanged", false);
      processingToTheNextPage.value = false;
      await store.commit("updateUser", { termsAgreement: true });
      if (store.getters.showSubscriptionEndPopup) {
        router.push("subscription-end");
      } else {
        router.push(route.query.redirectedFrom?.toString() ?? "");
      }
    };

    const agreementsChanged = () => {
      checked.value = model.value.termsAgreement;
    };

    onMounted(async () => {
      const consents = await http
        .get(`patient-portal/api/consents`)
        .then((res): Consents => res.data);

      termsAgreement.value =
        consents.consents.find((e) => e.name === "termsAgreement")?.content ??
        "";
      marketingAgreement.value =
        consents.consents.find((e) => e.name === "marketingAgreement")
          ?.content ?? "";
      tradeAgreement.value =
        consents.consents.find((e) => e.name === "commercialAgreement")
          ?.content ?? "";

      model.value = await http
        .get("patient-portal/api/patient-profile")
        .then((res): Model => res.data);
      checked.value = model.value.termsAgreement;
      consentsVersion.value = consents.version;
    });

    return {
      checked,
      processingToTheNextPage,
      acceptAgreements,
      hasPermission,
      agreementsChanged,
      t,
      iactions,
      model,
      termsAgreement,
      marketingAgreement,
      tradeAgreement,
      consentsVersion,
    };
  },
});

export default AgreementsComponent;
