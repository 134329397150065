import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-023fb63a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "agreements" }
const _hoisted_2 = { class: "agreements__box" }
const _hoisted_3 = {
  style: {"margin-top":"80px","overflow":"auto"},
  class: "make-appointment__agreemments"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_row, { type: "flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, null, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.t("MAKE_APPOINTMENT.AGGREMENTS")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_a_row, {
          class: "make-appointment__agreemment make-appointment__agreemment--intra-regulation",
          type: "flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_checkbox, {
                  checked: _ctx.model.termsAgreement,
                  "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.termsAgreement) = $event)),
                  onChange: _ctx.agreementsChanged
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", { innerHTML: _ctx.termsAgreement }, null, 8 /* PROPS */, _hoisted_4)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["checked", "onChange"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _withDirectives(_createVNode(_component_a_row, {
          class: "make-appointment__agreemment make-appointment__agreemment--marketing",
          type: "flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_checkbox, {
                  checked: _ctx.model.marketingAgreement,
                  "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.marketingAgreement) = $event)),
                  onChange: _ctx.agreementsChanged
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.marketingAgreement), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["checked", "onChange"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.hasPermission(_ctx.iactions.SHOW_MARKETING_AGREEMENT)]
        ]),
        _withDirectives(_createVNode(_component_a_row, {
          class: "make-appointment__agreemment make-appointment__agreemment--trade",
          type: "flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_checkbox, {
                  checked: _ctx.model.commercialAgreement,
                  "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.commercialAgreement) = $event)),
                  onChange: _ctx.agreementsChanged
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.tradeAgreement), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["checked", "onChange"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 512 /* NEED_PATCH */), [
          [_vShow, _ctx.hasPermission(_ctx.iactions.SHOW_TRADE_AGREEMENT)]
        ]),
        _createVNode(_component_a_row, {
          class: "make-appointment__agreemment make-appointment__agreemment--trade",
          type: "flex",
          style: {"margin-top":"15px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  class: "agreements__button",
                  type: "primary",
                  "html-type": "submit",
                  onClick: _ctx.acceptAgreements,
                  loading: _ctx.processingToTheNextPage,
                  disabled: !_ctx.checked
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("AGREEMENTS.GO")), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick", "loading", "disabled"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}